/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action, configure } from "mobx";
import { createContext, useContext } from "react";
import {
  IPatient,
  IAppointment,
  IProvider,
  IAppointmentList,
  IUserProvider,
  ISearchPatientTableObj,
} from "../services/api-types";
import {
  MeetingListSortOrder,
  SortValue,
  SortColumns,
  MeetingsView,
  OpenedModal,
  SendMessageModal,
  OpenAdhocMeeting,
  ISendMessageDetails,
  CustomAlert,
  OrientationType,
  ICustomLayoutType,
  IThemeColors,
} from "./global-store-types";
import { ConstantVariables } from "../constants/Constants";

configure({ enforceActions: "observed" });

export class GlobalStore {
  @observable patient: IPatient | null = null;

  @observable provider: IProvider | undefined;

  @observable appointmentsList: IAppointmentList[] = [];

  @observable globalAppointmentsList: IAppointmentList[] = [];

  @observable appointment: IAppointment | undefined;

  @observable openedModal: OpenedModal = "none";

  @observable openAdhocModal: OpenAdhocMeeting = "none";

  @observable statusTab = "All";

  @observable meetingListOrder: MeetingListSortOrder = {
    patientName: "asc",
    appointmentTime: "asc",
    providerName: "asc",
  };

  @observable meetingsView: MeetingsView = "table";

  @observable spinner = false;

  @observable userSpecificProviderList: IUserProvider[] = [];

  @observable selectedProviderId = ConstantVariables.My_Provider_Text;

  @observable selectedProviderName = "My Providers";

  @observable patientMeetingId: string | undefined;

  @observable meetingDetailedView: IAppointmentList | null = null;

  @observable currentMeetingDetailedViewIndex: number | null = null;

  @observable currentMeetingListLength = 0;

  @observable actionMenuSelectedMeeting: IAppointmentList | null = null;

  @observable showSendMessagePopup: SendMessageModal = "none";

  @observable loginUserEmail = "";

  @observable loginUserAadId = "";

  @observable sendMessageDetails: ISendMessageDetails = {
    televisitId: "",
    meetingUrl: "",
    messageText: "",
    // Admin: this.loginUserEmail,
  };

  @observable searchMeetingString: string | null = "";

  @observable showAlertMessage: CustomAlert = "";

  @observable dashboardAlertMessage = "";

  @observable patientGridObj: ISearchPatientTableObj[] = [];

  @observable showSuccessScreenHeader = false;

  @observable selectedDate: Date = new Date();

  @observable preMessageType = "";

  @observable providerSearchText = "";

  @observable windowWidth = 0;

  @observable navOpen = false;

  @observable isAllProviderList = false;

  @observable mobileOrientation: OrientationType = "portrait";

  @observable customLayoutData: ICustomLayoutType = {
    layoutStyle: "",
    appTagLine: "",
    fileName: "",
    id: "",
    uniqueFileName: "",
    uploadedBy: "",
    uploadedTime: "",
    url: "",
  };

  @observable themeData: IThemeColors | null = null;

  @action setThemeData = (themeData: IThemeColors | null) => {
    this.themeData = themeData;
  };

  @action setCustomLayoutData = (value: ICustomLayoutType) => {
    this.customLayoutData = value;
  };

  @action setisAllProviderList = (value: boolean) => {
    this.isAllProviderList = value;
  };

  @action setIsNavOpen = (value: boolean) => {
    this.navOpen = value;
  };

  @action setWindowWidth = (width: number) => {
    this.windowWidth = width;
  };

  @action setMobileOrientation = (orientation: OrientationType) => {
    this.mobileOrientation = orientation;
  };

  @action setProviderSearchText = (searchText: string) => {
    this.providerSearchText = searchText;
  };

  @action setPreMessageType = (mgs: string) => {
    this.preMessageType = mgs;
  };

  @action setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };

  @action setPatient = (patient: IPatient | null) => {
    this.patient = patient;
  };

  @action setProvider = (provider: IProvider) => {
    this.provider = provider;
  };

  @action setAppointmentsList = (appointmentsList: IAppointmentList[]) => {
    this.appointmentsList = appointmentsList;
  };

  @action setGlobalAppointmentsList = (
    appointmentsList: IAppointmentList[]
  ) => {
    this.globalAppointmentsList = appointmentsList;
  };

  @action deleteAppointmentList = (appointmentId: string) => {
    this.globalAppointmentsList = this.globalAppointmentsList.filter(
      (value: IAppointmentList) => value.televisitId !== appointmentId
    );
    this.appointmentsList = this.appointmentsList.filter(
      (value: IAppointmentList) => value.televisitId !== appointmentId
    );
  };

  @action updateAppointmentList = (
    appointmentId: string,
    appointment: IAppointmentList
  ) => {
    const globalAppointmentsListIndex = this.globalAppointmentsList.findIndex(
      (x) => x.televisitId === appointmentId
    );
    const appointmentsListIndex = this.appointmentsList.findIndex(
      (x) => x.televisitId === appointmentId
    );
    this.globalAppointmentsList[globalAppointmentsListIndex] = appointment;
    this.appointmentsList[appointmentsListIndex] = appointment;
  };

  @action insertAppointmentList = (appointment: IAppointmentList) => {
    this.globalAppointmentsList.push(appointment);
    if (
      (this.selectedProviderId.toLowerCase() === "myproviders" &&
        this.userSpecificProviderList.some(
          (searchElement: IUserProvider) =>
            searchElement.providerId ===
            appointment.providerRequest[0].providerId
        )) ||
      this.selectedProviderId === appointment.providerRequest[0].providerId
    )
      this.appointmentsList.push(appointment);
  };

  @action setAppointment = (appointments: IAppointment) => {
    this.appointment = appointments;
  };

  @action setOpenedModal = (modalOpened: OpenedModal) => {
    this.openedModal = modalOpened;
  };

  @action setOpenAdhocModal = (value: OpenAdhocMeeting) => {
    this.openAdhocModal = value;
  };

  @action setStatusTab = (status: string) => {
    this.statusTab = status;
  };

  @action setMeetingListOrder = (sortValue: SortValue) => {
    if (this.meetingListOrder[sortValue] === "asc") {
      this.meetingListOrder[sortValue] = "desc";
    } else {
      this.meetingListOrder[sortValue] = "asc";
    }
  };

  @action getSortedMeetingList = (
    sortValue: SortValue,
    sortKey: SortColumns,
    sortType: string
  ) => {
    if (this.meetingListOrder[sortValue] === "asc") {
      this.appointmentsList = this.appointmentsList?.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a, b) => {
          if (sortType === "date") {
            return sortKey === "providerRequest[0].provider_first_name"
              ? null
              : (new Date(a[sortKey] as any) as any) -
                  (new Date(b[sortKey] as any) as any);
          }

          return sortKey === "providerRequest[0].provider_first_name"
            ? (a.providerRequest[0].firstName as any).localeCompare(
                b.providerRequest[0].firstName as any
              )
            : (a[sortKey] as any).localeCompare(b[sortKey] as any);
        }
      );
    } else {
      this.appointmentsList = this.appointmentsList?.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a, b) => {
          if (sortType === "date") {
            return sortKey === "providerRequest[0].provider_first_name"
              ? null
              : (new Date(b[sortKey] as any) as any) -
                  (new Date(a[sortKey] as any) as any);
          }

          return sortKey === "providerRequest[0].provider_first_name"
            ? (b.providerRequest[0].firstName as any).localeCompare(
                a.providerRequest[0].firstName as any
              )
            : (b[sortKey] as any).localeCompare(a[sortKey] as any);
        }
      );
    }
  };

  @action setMeetingsView = (view: MeetingsView) => {
    this.meetingsView = view;
  };

  @action setSpinner = (status: boolean) => {
    this.spinner = status;
  };

  @action setUserSpecificProviderList = (data: IUserProvider[]) => {
    this.userSpecificProviderList = [...data];
  };

  @action setSelectedProviderId = (providerId: string) => {
    this.selectedProviderId = providerId;
  };

  @action setSelectedProviderName = (providerName: string) => {
    this.selectedProviderName = providerName;
  };

  @action resetSelectedProviderName = () => {
    this.selectedProviderName = "My Providers";
  };

  @action resetSelectedProviderId = () => {
    this.selectedProviderId = ConstantVariables.My_Provider_Text;
  };

  @action setPatientMeetingId = (id: string) => {
    this.patientMeetingId = id;
  };

  @action setMeetingDetailedView = (
    appointments: IAppointmentList[],
    index: number | null,
    currentLength: number
  ) => {
    this.meetingDetailedView = index !== null ? appointments[index] : null;
    this.currentMeetingDetailedViewIndex = index;
    this.currentMeetingListLength = currentLength;
  };

  @action setMeetingDetailedViewStatus = () => {
    if (this.meetingDetailedView !== null) {
      const newMeetingDetailedView = JSON.parse(
        JSON.stringify(this.meetingDetailedView)
      );
      newMeetingDetailedView.status = "Completed";
      this.meetingDetailedView = newMeetingDetailedView;
    }
  };

  @action setActionMenuSelectedMeeting = (
    appointment: IAppointmentList | null
  ) => {
    this.actionMenuSelectedMeeting = appointment;
  };

  @action setShowSendMessagePopup = (value: SendMessageModal) => {
    this.showSendMessagePopup = value;
  };

  @action updateSendMessage = (message: string) => {
    this.sendMessageDetails = {
      ...this.sendMessageDetails,
      messageText: message,
    };
  };

  @action updateTelevisitId = (teleId: string, meetingLink: string) => {
    this.sendMessageDetails = {
      ...this.sendMessageDetails,
      televisitId: teleId,
      meetingUrl: meetingLink,
    };
  };

  @action setLoginUserEmail = (loginEmail: string) => {
    this.loginUserEmail = loginEmail;
  };

  @action setLoginUserAadId = (aadId: string) => {
    this.loginUserAadId = aadId;
  };

  @action setShowAlertMessage = (state: CustomAlert) => {
    this.showAlertMessage = state;
  };

  @action setDashboardAlertMessage = (message: string) => {
    this.dashboardAlertMessage = message;
  };

  @action resetPatientGridObj = () => {
    this.patientGridObj = [];
  };

  @action resetPatientObj = () => {
    this.patient = null;
  };

  @action setPatientGridObj = (obj: ISearchPatientTableObj) => {
    this.patientGridObj = [];
    this.patientGridObj.push(obj);
    if (obj.selected) {
      this.patient = {
        patientId: obj.patientId,
        patientFirstName: obj.firstName,
        patientMiddleName: obj.middleName,
        patientLastName: obj.lastName,
        dob: obj.dob,
        patientEmails: obj.emails,
        patientPhones: obj.phones,
        isPatientVerified: obj.isVerified,
        sendSMS: obj.sendSMS,
      };
    } else {
      this.patient = null;
    }
  };

  @action setPatientGridArray = (arr: ISearchPatientTableObj[]) => {
    this.patientGridObj = arr;

    if (this.patientGridObj.length > 0) {
      this.patient = {
        patientId: arr[0].patientId,
        patientFirstName: arr[0].firstName,
        patientMiddleName: arr[0].middleName,
        patientLastName: arr[0].lastName,
        dob: arr[0].dob,
        patientEmails: arr[0].emails,
        patientPhones: arr[0].phones,
        isPatientVerified: arr[0].isVerified,
        sendSMS: arr[0].sendSMS,
      };
    }
  };

  @action onPatientCheckBoxChangeEvent = (
    checked: boolean,
    selectedValue: ISearchPatientTableObj
  ) => {
    if (this.patientGridObj.length > 0) {
      const patientGridObjNew: ISearchPatientTableObj[] = JSON.parse(
        JSON.stringify(this.patientGridObj)
      );
      if (
        patientGridObjNew.filter(
          (value: ISearchPatientTableObj) => value.selected === true
        ).length > 0
      ) {
        patientGridObjNew.filter(
          (value: ISearchPatientTableObj) => value.selected === true
        )[0].selected = false;
      }
      patientGridObjNew.filter(
        (value: ISearchPatientTableObj) =>
          value.patientId === selectedValue.patientId
      )[0].selected = checked;
      this.setPatientGridArray([...patientGridObjNew]);
      if (checked) {
        this.patient = {
          patientId: selectedValue.patientId,
          patientFirstName: selectedValue.firstName,
          patientMiddleName: selectedValue.middleName,
          patientLastName: selectedValue.lastName,
          dob: selectedValue.dob,
          patientEmails: selectedValue.emails,
          patientPhones: selectedValue.phones,
          isPatientVerified: selectedValue.isVerified,
          sendSMS: selectedValue.sendSMS,
        };
      } else {
        this.patient = null;
      }
    }
  };

  @action setShowSuccessScreenHeader = (v: boolean) => {
    this.showSuccessScreenHeader = v;
  };

  @action setSearchMeetingString = (searchString: string) => {
    this.searchMeetingString = searchString;
  };
}

export const StoreContext = createContext<GlobalStore>({} as GlobalStore);
export const GlobalStoreProvider = StoreContext.Provider;
export const useGlobalStore = (): GlobalStore => useContext(StoreContext);
