export const ConstantVariables = {
  Appointment_Text: "Appointments",
  My_Provider_Text: "myProviders",
  All_Provider_Text: "allProviders",
  Today_Text: "Today",
  Meeting_List_Header_Column_One_Text: "Patient Name",
  Meeting_List_Header_Column_Two_Text: "Patient ID",
  Meeting_List_Header_Column_Three_Text: "Appointment Date",
  Meeting_List_Header_Column_Four_Text: "Appointment Time",
  Meeting_List_Header_Column_Five_Text: "Status",
  Meeting_List_Header_Column_Six_Text: "Provider",
  Cancel_Visit_Button_Text: "Cancel Visit",
  Join_Visit_Button_Text: "Join Visit",
  Reschedule_Button_Text: "Reschedule",
  Send_Message_Button_Text: "Send Message",
  No_Records_Text: "No Records",
  Appointment_Date_Time_Text: "Appointment Date & Time",
  Provider_Text: "Provider",
  Join_Call_Button_Text: "Join Visit",
  End_Visit_Button_Text: "End Visit",
  Back_Text: "Back",
  Next_Text: "Next",
  Previous_Text: "Previous",
  Duration_Text: "Duration",
  Duration_Format_Text: "(HH:MM:SS)",
  Common_Error_Text: "Something went  wrong. Please try again.",
  Remove_Participant_Button_Text: "Remove Participant",
  Patient_Text: "Patient",
  Details_Button_Text: "Details",
  Patient_Phone_Number_Text: "Patient Phone Number",
  Patient_Email_Address_Text: "Patient Email Address",
  Check_Patient_Button_Text: "Check Patient",
  Patient_Phone_Validation_Text: "Please provide valid phone number",
  Patient_Email_Validation_Text: "Please enter valid patient email address",
  Patient_Name_Validation_Text: "Please provide patient full name",
  Patient_Exists_Message_Text: "Patient with same details already exists.",
  Invalid_Date_Format_Validation_Text: "Invalid date format",
  Invalid_Time_Format_Validation_Text: "Invalid time format",
  Patient_Find_Existing_Message_Text:
    "Please enter valid input in any of the above fields to find the existing patient record.",
  Patient_FullName_Text: "Patient Full Name",
  Recent_Visit_Date_Text: "Recent Visit Date",
  Date_Text: "Date",
  Time_Text: "Time",
  Minutes_Text: "Minutes",
  Contact_Reschedule_Cancel_Text:
    "Contact us to re-schedule or cancel the appointment. Cancellation will be accepted prior to 24 hours",
  Copy_Link_Button_Text: "Copy Link",
  Click_To_Join_Button_Text: "Click Here to Join",
  Appointment_Scheduled_Text: "Appointment Scheduled!",
  AdhocMeetingSuccessScreen: "Successfully Scheduled Adhoc Appointment for ",
  Confirm_Button_Text: "Confirm",
  Cancel_Confirm_Text: "Please confirm on cancelling the visit.",
  Select_Appointment_Time_Text: "Please select appointment time",
  Select_Appointment_Date_Text: "Please select appointment date",
  Select_Appointment_Duration_Text: "Please select appointment duration",
  My_Providers_Text: "My Providers",
  All_Providers_Text: "All Providers",
  Remove_Provider_Button_Text: "Remove Provider",
  No_Result_Text: "No Result",
  Send_Button_Text: "Send",
  Cancel_Button_Text: "Cancel",
  Sent_Message_Meeting_Room_Text: "Send Message to Meeting Room",
  Enter_Message_Meeting_Room_Text: "Select Message Type",
  New_Patient_Text: "New Patient",
  Existing_Patient_Text: "Existing Patient",
  Submit_Button_Text: "Submit",
  First_Name_Text: "First Name",
  Last_Name_Text: "Last Name",
  First_Name_Validation_Text: "Please provide patient first name",
  Last_Name_Validation_Text: "Please provide patient last name",
  Date_Of_Birth_Text: "Date of Birth",
  Email_Address_Text: "Email Address",
  User_Details_Failed_Message_Text: "Failed to retrieve user details",
  Select_Provider_Validation_Text: "Please select provider",
  ErrorBoundaryTextMessage: "Something went wrong. Please try again.",
  DashBoardRefreshTime: 30000,
  PopupHideTime: 5000,
  PatientAppLink: "https://fe-virtualvisits-dev-eastus.azurewebsites.net/",
  ClientID: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4",
  Authority:
    "https://login.microsoftonline.com/e03de411-1263-4660-8112-fe1342e1dca3",
  Scope: "a5ba5d31-5daf-4cfd-85b0-f3958a9f90f4/.default",
  TenantID: "e03de411-1263-4660-8112-fe1342e1dca3",
  mobileMaxWidth: 900,
  tabletMaxWidth: 1400,
  windowsWidthForDDView: 1577,
  tabletWidthForDDView: 1304,
};

export const MeetingStatus = {
  All: "All",
  InProgress: "In-Progress",
  Waiting: "Waiting",
  Delayed: "Delayed",
  Scheduled: "Scheduled",
  Completed: "Completed",
};

export class AppInsightConstants {
  public static readonly Information = "Information";

  public static readonly Error = "Error";

  public static readonly Exception = "Exception";
}
